exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.30.11\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ssr-markup .hover-tile-content-block noscript img{display:none}.HoverTileContentBlock__ImageBlock_image-wrapper{overflow:hidden;clear:both;width:100%;text-align:center}.HoverTileContentBlock__ImageBlock_image-wrapper img{height:100%;top:0;left:0;right:0;bottom:0}.HoverTileContentBlock__ImageBlock_image-wrapper img.HoverTileContentBlock__ImageBlock_image-best-view{-o-object-fit:cover;object-fit:cover;position:absolute;width:100%}.HoverTileContentBlock__ImageBlock_image-wrapper img.HoverTileContentBlock__ImageBlock_image-base-on-the-width{-o-object-fit:contain;object-fit:contain;position:absolute;width:100%}.HoverTileContentBlock__ImageBlock_image-wrapper img.HoverTileContentBlock__ImageBlock_image-base-on-height{-o-object-fit:fill;object-fit:fill;position:absolute;width:100%}.HoverTileContentBlock__ImageBlock_image-wrapper img.HoverTileContentBlock__ImageBlock_image-do-not-scale{-o-object-fit:none;object-fit:none}.HoverTileContentBlock__ImageBlock_image-wrapper .HoverTileContentBlock__ImageBlock_text-wrapper{position:absolute;left:0;top:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%;padding:16px}.HoverTileContentBlock__ImageBlock_image-wrapper .HoverTileContentBlock__ImageBlock_apply-font-color *{color:inherit}.HoverTileContentBlock__ImageBlock_image-wrapper.HoverTileContentBlock__ImageBlock_show-placeholder{background:grey;width:100%;height:100%;background:#f7f7f7}", ""]);

// exports
exports.locals = {
	"image-wrapper": "HoverTileContentBlock__ImageBlock_image-wrapper",
	"imageWrapper": "HoverTileContentBlock__ImageBlock_image-wrapper",
	"image-best-view": "HoverTileContentBlock__ImageBlock_image-best-view",
	"imageBestView": "HoverTileContentBlock__ImageBlock_image-best-view",
	"image-base-on-the-width": "HoverTileContentBlock__ImageBlock_image-base-on-the-width",
	"imageBaseOnTheWidth": "HoverTileContentBlock__ImageBlock_image-base-on-the-width",
	"image-base-on-height": "HoverTileContentBlock__ImageBlock_image-base-on-height",
	"imageBaseOnHeight": "HoverTileContentBlock__ImageBlock_image-base-on-height",
	"image-do-not-scale": "HoverTileContentBlock__ImageBlock_image-do-not-scale",
	"imageDoNotScale": "HoverTileContentBlock__ImageBlock_image-do-not-scale",
	"text-wrapper": "HoverTileContentBlock__ImageBlock_text-wrapper",
	"textWrapper": "HoverTileContentBlock__ImageBlock_text-wrapper",
	"apply-font-color": "HoverTileContentBlock__ImageBlock_apply-font-color",
	"applyFontColor": "HoverTileContentBlock__ImageBlock_apply-font-color",
	"show-placeholder": "HoverTileContentBlock__ImageBlock_show-placeholder",
	"showPlaceholder": "HoverTileContentBlock__ImageBlock_show-placeholder"
};