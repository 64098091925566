import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageBlock from './imageBlock/ImageBlock';
import HoverBlock from './hoverBlock/HoverBlock';
import 'animate.css';
import styles from './HoverTile.module.scss';

const HoverTile = ({ model, isDesignerMode, id }) => {
  const elementId = `scc-cb-hover-tile-${id}`;
  const coreHeightVars = ['--minHeight_Desktop', '--minHeight_Tablet', '--minHeight_Mobile'];
  const { defaultSection, hoverSection, hoverAnimationSection } = model;
  const [showHover, setShowHover] = useState(false);
  const [isHeightEmpty, setIsHeightEmpty] = useState(false);
  const canShowHover =
    hoverSection.hoverText ||
    hoverSection.buttonText ||
    hoverSection.hoverTileImage ||
    hoverSection.hoverBackgroundColor
      ? true
      : false;

  useEffect(() => {
    const getElement = document.getElementById(elementId),
          styles = getElement?.parentNode.style;
    if(styles)
      setIsHeightEmpty(Object.values(styles).every(element=> !coreHeightVars.includes(element))); 
  }, []);

  return (
    <div
      id={elementId} 
      className={classNames(styles.wrapper, 'hover-tile-content-block', isDesignerMode && styles.designerMode, isHeightEmpty && styles.defaultHeight)}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}
    >
      <ImageBlock
        imagePath={defaultSection.defaultTileImage}
        text={defaultSection.defaultText}
        textColor={defaultSection.defaultFontColor}
        showHover={showHover}
        canShowHover={canShowHover}
        imageScaling={defaultSection.imageScaling}
      />
      {
        <div
          key={Math.random()}
          className={classNames(
            styles.hoverContainer,
            'animated',
            !canShowHover ? `${hoverAnimationSection.animationType}` : 'fadeIn',
          )}
          style={{ visibility: showHover ? 'visible' : 'hidden' }}
        >
          <ImageBlock
            imagePath={hoverSection.hoverTileImage}
            text={defaultSection.hoverText}
            textColor={defaultSection.hoverFontColor}
            imageScaling={defaultSection.imageScaling}
          />
        </div>
      }
      {canShowHover && showHover && (
        <HoverBlock
          hoverSection={hoverSection}
          showHover={showHover}
          animationType={
            hoverAnimationSection && hoverAnimationSection.animationType
          }
        />
      )}
    </div>
  );
};

HoverTile.propTypes = {
  id:PropTypes.string,
  model: PropTypes.shape({
    defaultSection: PropTypes.object,
    hoverSection: PropTypes.object,
    hoverAnimationSection: PropTypes.object,
  }),
  isDesignerMode:PropTypes.bool,
};

export default React.memo(HoverTile);